$rootPath: "/v40/23179";
// Override variables in the default theme
$brand-primary: #3f494f;
$brand-info: #3f494f;

@import "Theme";

.btn-primary {
    background-color: #f8a331;

    &:active, &:hover, &:focus {
        background-color: darken(#f8a331, 10%);
        border-color: darken(#f8a331, 12%);
    }
}
